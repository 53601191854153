import { observer } from "mobx-react-lite";
import { useEffect } from "react"
import controller from './api.constructor.controller'
import { useParams } from "react-router-dom";
import { Titles } from "../../../layouts/Titles";
import i from "../../../translations/i";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Row, Col, Select, Input, Tag, Space, Table, Button, Divider } from 'antd'
import { runInAction } from "mobx";

export const ApiConstructorList = observer(() => {

  useEffect(() => {
    controller.getTypes()
    controller.getList()
  }, [])

  const buttons = [
    { title: i.t('def:actions.create'), href: `/settings/rest/0`, type: 'primary' }
  ]

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: i.t('def:Method'), dataIndex: 'method', key: 'method' },
    {
      title: i.t('def:Type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => controller.types.find((el: any) => el.type === type) ? controller.types.find((el: any) => el.type === type).label : type
    },
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="dashed"
            shape="circle"
            icon={<EditOutlined />}
            href={`/settings/rest/${record.id}`}
          />
          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.deleteMethod(record.id)}
          />
        </Space>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Rest constructor')}
        buttons={buttons}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})

export const ApiConstructorItem = observer(() => {

  const { id }: any = useParams()

  useEffect(() => {
    controller.getTypes()
    controller.getItem(id)
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/settings/rest`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return(
    <>
      <form onSubmit={(e) => controller.save(e)}>
        <Titles
          title={i.t('def:Edit rest constructor item')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col span={12}>
            <label>{i.t('def:Select type')}</label>
            <Select
              options={controller.types.map((el: any) => ({ label: el.label, value: el.type, disabled: !!el.dev }))}
              style={{ width: `100%` }}
              value={controller.item.type}
              onChange={(e: any) => runInAction(() => controller.item.type = e)}
            />
          </Col>
          <Col span={12}>
            <label>{i.t('def:Select method')}</label>
            <Select
              options={[{ label: 'GET', value: `get` }, { label: 'POST', value: `post` }]}
              style={{ width: `100%` }}
              value={controller.item.method}
              onChange={(e: any) => runInAction(() => controller.item.method = e)}
            />
          </Col>
          <Col span={24}>
            <label>{i.t('def:URI')}</label>
            <Input
              value={controller.item.path}
              onChange={(e: any) => runInAction(() => controller.item.path = e.target.value)}
              required
            />
          </Col>
          {controller.types.find((el: any) => el.type === controller.item.type) && controller.types.find((el: any) => el.type === controller.item.type).params &&
            <>
              <Divider />
              <Col span={24}>
                <h3>{i.t('def:We are transmitting')}:</h3>
                {controller.types.find((el: any) => el.type === controller.item.type).params.map((e: any) => (
                  <p><Tag>{e.key}</Tag> {e.label} {e.example && <>({i.t('def:example')}: {e.example})</>}</p>
                ))}
                <p></p>
              </Col>
              <Divider />
            </>
          }
          <Col span={24}>
            <h3>{i.t('def:User params')}:</h3>
          </Col>
          {controller.item.params.map((e: any, index: number) => (
            <Col span={24}>
              <Row gutter={[20, 20]} justify="space-between" align="bottom">
                <Col span={6}>
                  <label>{i.t('def:Location param')}</label>
                  <Select
                    options={[
                      { label: i.t('def:Data'), value: 'data' },
                      { label: i.t('def:Header'), value: 'header' }
                    ]}
                    style={{ width: `100%` }}
                    value={e.location}
                    onChange={(e: any) => runInAction(() => controller.item.params[index].location = e)}
                  />
                </Col>
                <Col span={6}>
                  <label>{i.t('def:Key')}</label>
                  <Input
                    value={e.label}
                    onChange={(e: any) => runInAction(() => controller.item.params[index].label = e.target.value)}
                    required
                  />
                </Col>
                <Col span={6}>
                  <label>{i.t('def:Value')}</label>
                  <Input
                    value={e.value}
                    onChange={(e: any) => runInAction(() => controller.item.params[index].value = e.target.value)}
                    required
                  />
                </Col>
                <Col span={6}>
                  <Button
                    icon={<DeleteOutlined />}
                    shape="circle"
                    type="dashed"
                    onClick={() => controller.deleteParam(index)}
                    danger
                  />
                </Col>
              </Row>
            </Col>
          ))}
          <Col span={24}>
            <Button type="dashed" onClick={() => controller.addParam()}>{i.t('def:Add param')}</Button>
          </Col>
        </Row>

      </form>
    </>
  )
})