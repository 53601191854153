import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Row, Col, Button, Select, Switch, Modal, InputNumber, Divider, DatePicker } from "antd";
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import controller from '../settings/project/project.controller'
import { storeLocal } from '../../helpers/stores'
import { ErrorByPricingPlan } from '../../components/ErrorByPricingPlan'
import dayjs from "dayjs"
import { TextEditor } from "../../helpers/TextEditor"

export const BonusProgram = observer(() => {

    useEffect(() => {
        controller.get()
    }, [])

    const buttons = [
        { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
    ]

    return controller.isset ? (
        <>
            <form onSubmit={(e) => controller.save(e)}>
                <Titles
                    title={i.t('def:bonus.title')}
                    buttons={buttons}
                />

                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <label>{i.t('def:bonus.description')}</label>

                        <TextEditor
                          content={controller.project.bonus_description ?? ''}
                          returned={(e: any) => controller.project.bonus_description = e}
                        />
                    </Col>
                    <Divider />
                    <Col span={24} className="switch-block">
                        <div className="element">
                            <div></div>
                            <Switch
                                checked={controller.project.bonus_card}
                                onChange={e => controller.set('bonus_card', e)}
                                size="small"
                            />
                            <div>
                                <span>{i.t('def:bonus.bonus_card')}</span>
                            </div>
                        </div>
                    </Col>
                    {controller.project.bonus_card
                        ? <>
                            <Col span={12}>
                                <label>{i.t('def:bonus.bonus_card_type')}</label>
                                <Select
                                    value={controller.project.bonus_card_type}
                                    options={[
                                        { label: "QRCode", value: "qrcode" },
                                        { label: "Barcode", value: "barcode" },
                                    ]}
                                    onChange={(e: string) => controller.set('bonus_card_type', e)}
                                    style={{ width: `100%` }}
                                />
                            </Col>
                            <Col span={12}>
                                <label>{i.t('def:bonus.bonus_card_value')}</label>
                                <Select
                                    value={controller.project.bonus_card_value}
                                    options={[
                                        { label: i.t('def:bonus.card.user_phone'), value: "user.phone" },
                                        { label: i.t('def:bonus.card.user_external_id'), value: "user.external_id" },
                                    ]}
                                    onChange={(e: string) => controller.set('bonus_card_value', e)}
                                    style={{ width: `100%` }}
                                />
                            </Col>
                        </>
                        : <></>
                    }
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24} className="switch-block">
                        <ErrorByPricingPlan
                            plan={1}
                            content={
                                    <div className="element">
                                        <div></div>
                                        <Switch
                                            checked={controller.project.bonus}
                                            onChange={e => controller.set('bonus', e)}
                                            size="small"
                                            disabled={storeLocal.get('pricing_plan') < 1}
                                        />
                                        <div>
                                            <span>{i.t('def:bonus.enabled')}</span>
                                        </div>
                                    </div>
                            }
                        />
                    </Col>
                </Row>

                {controller.project.bonus
                    ? <>
                        <Divider />

                        <Row gutter={[20, 20]}>
                            <Col span={12} className="switch-block">
                                <div className="element">
                                    <div></div>
                                    <Switch
                                        checked={controller.project.welcome_bonus}
                                        onChange={e => controller.set('welcome_bonus', e)}
                                        size="small"
                                    />
                                    <div>
                                        <span>{i.t('def:bonus.hello.hello_switch')}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <label>{i.t('def:bonus.hello.hello_count')}</label>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={(e) => controller.set('welcome_bonus_size', e)}
                                    value={controller.project.welcome_bonus_size}
                                />
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <h3>{i.t('def:bonus.combustion.description')}</h3>
                            </Col>
                            <Col span={12} className="switch-block">
                                <div className="element">
                                    <div></div>
                                    <Switch
                                        checked={controller.project.bonuses_expire}
                                        onChange={e => controller.set('bonuses_expire', e)}
                                        size="small"
                                    />
                                    <div>
                                        <span>{i.t('def:bonus.combustion.combustion_switch')}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <label>{i.t('def:bonus.combustion.combustion_date')}</label>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={(e) => controller.set('combustion_days', e)}
                                    value={controller.project.combustion_days}
                                />
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <h3>{i.t('def:bonus.cart_percent')}</h3>
                            </Col>
                            <Col span={12}>
                                <label>{i.t('def:bonus.cart_percent_count')}</label>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={(e) => controller.set('percent_bonus_cart', e)}
                                    value={controller.project.percent_bonus_cart}
                                />
                            </Col>
                            <Col span={12}>
                                <label>{i.t('def:bonus.cashback_percent')}</label>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onChange={(e) => controller.set('cashback_percent', e)}
                                    value={controller.project.cashback_percent}
                                />
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Button type="dashed" danger onClick={() => controller.openBonusMassAssignment()}>{i.t('def:Mass Assignment')}</Button>
                            </Col>
                        </Row>

                      <Modal
                        title={i.t('def:Mass Assignment')}
                        open={controller.bonusMassAssignment}
                        onOk={() => controller.saveBonusMassAssignment()}
                        onCancel={() => controller.closeBonusMassAssignment()}
                      >
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  value={controller.bonusMassAssignmentData.bonuses_add}
                                  onChange={(e) => controller.bonusMassAssignmentData.bonuses_add = e ? e : 1}
                                  min={1}
                                  required
                                />
                            </Col>
                            <Col span={24}>
                                <DatePicker
                                  style={{ width: '100%' }}
                                  value={controller.bonusMassAssignmentData.bonuses_add_combustion_date ? dayjs(controller.bonusMassAssignmentData.bonuses_add_combustion_date * 1000).utc() : null}
                                  placeholder={i.t('def:combustion_date')}
                                  onChange={e => {
                                      if(e) {
                                          controller.bonusMassAssignmentData.bonuses_add_combustion_date = dayjs(e).unix()
                                      }
                                  }}
                                />
                            </Col>
                        </Row>
                      </Modal>
                    </>
                    : <></>
                }
            </form>
        </>
    ) : <></>
})