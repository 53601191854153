import { makeAutoObservable } from 'mobx'
import { openApi, request } from '../request'
import i from '../../translations/i'
import { __cpl } from "../../front/catalog/custom_product_lists/__cpl";

interface SelectSelect {
    type: string
    value: any
    value_array: any[]
    element: any
}

class __select {

    limit = 10
    offset = 0
    page = 1
    total = 0

    filter_name: any = null

    categoryID = 0

    types = [
        { label: i.t('def:selectTypes.none'), value: '' },
        { label: i.t('def:selectTypes.productCategory'), value: 'category' },
        { label: i.t('def:Product Group'), value: 'product_group' },
        { label: i.t('def:selectTypes.cart'), value: 'cart' },
        { label: i.t('def:selectTypes.favorite'), value: 'favorite' },
        { label: i.t('def:selectTypes.cabinet'), value: 'cabinet' },
        { label: i.t('def:selectTypes.home'), value: 'home' },
        { label: i.t('def:selectTypes.product'), value: 'product' },
        { label: i.t('def:Products List'), value: 'custom_product_list' },
        { label: i.t('def:selectTypes.contacts'), value: 'contacts' },
        { label: i.t('def:selectTypes.depart'), value: 'place' },
        { label: i.t('def:Places Group'), value: 'places_group' },
        { label: i.t('def:selectTypes.feedback'), value: 'feedback' },
        { label: i.t('def:selectTypes.questioning'), value: 'forms' },
        { label: i.t('def:selectTypes.url'), value: 'url' },
        { label: i.t('def:selectTypes.url_blank'), value: 'url_blank' },
        { label: i.t('def:selectTypes.jivo'), value: 'jivo' },
        { label: i.t('def:selectTypes.orders'), value: 'orders' },
        { label: i.t('def:selectTypes.notifications'), value: 'notifications' },
        { label: i.t('def:selectTypes.promo'), value: 'promo' },
        { label: i.t('def:selectTypes.referral'), value: 'referral' },
        { label: i.t('def:selectTypes.bonus_system'), value: 'bonus_system' },
        { label: i.t('def:selectTypes.bonus_card'), value: 'bonus_card' },
        { label: i.t('def:selectTypes.article'), value: 'article' },
        { label: i.t('def:selectTypes.article_categories'), value: 'article_categories' },
        { label: i.t('def:selectTypes.addresses'), value: 'addresses' },
        { label: i.t('def:selectTypes.cards'), value: 'cards' },
        { label: i.t('def:selectTypes.coupons'), value: 'coupons' },

    ]

    select: SelectSelect = {
        type: '',
        value: '',
        value_array: [],
        element: null
    }

    save_type: boolean = true

    list: any[] = []
    statuses: any = []

    elements: any = []
    element: any = null

    constructor() {
        makeAutoObservable(this)
    }

    clear() {

    }

    setTypesElements() {
        this.types = this.types.filter(el => el.value !== '')
    }

    getTypeByCode(code: string) {
        const index = this.types.findIndex(el => el.value === code)
        if(index > -1) return this.types[index].label
        return ''
    }

    getDefaultValueByType(type: string) {
        if(type === 'products') return []
        else if (type === 'custom_product_list') return
        else return ''
    }

    setValue(value: any) {
        console.log(value)
        this.select.value = value
    }

    setValueMultiple(value: any, record: any, returnFunction: any, custom: boolean = false) {

        if(custom) {
            if(this.select.value_array.findIndex((el: any) => `${el.id}` === `${value}`) > -1) {
                this.select.value_array = this.select.value_array.filter((el: any) => `${el.id}` !== `${value}`)
            } else {
                let array = this.select.value_array
                array.push({
                    id: value,
                    sorting: 500
                })
                this.select.value_array = array

                if(returnFunction)
                    returnFunction(record)
            }
        } else {
            if(this.select.value_array.findIndex((el: any) => `${el}` === `${value}`) > -1) {
                this.select.value_array = this.select.value_array.filter((el: any) => `${el}` !== `${value}`)
            } else {
                let array = this.select.value_array
                array.push(value)
                this.select.value_array = array

                if(returnFunction)
                    returnFunction(record)
            }
        }
    }

    setElements(elements: any) {
        this.elements = elements
    }

    setElement(element: any) {
        this.element = element
    }

    setRefer(refer: any = null) {
        if(refer) {
            this.select.type = refer.type
            this.select.value = refer.value
            this.select.element = refer.element
        }
    }

    setType(type: string, element: any = false) {

        this.limit = 10
        this.offset = 0
        this.page = 1
        this.total = 0

        this.select = {
            type: type,
            value: this.getDefaultValueByType(type),
            value_array: [],
            element: null
        }

        if(element) {
            if(Array.isArray(element)) this.select.value_array = element
            else this.select.value = element
        }
    }

    setPage(page: number) {
        this.offset = page > 1 ? (this.limit * page) - this.limit : 0
        this.page = page
    }

    getArticles() {
        openApi('get', `/news?limit=${this.limit}&page=${this.page}&with[]=image`)
            .then(success => {
                this.list = success.data
                this.total = success.total
            })
    }

    getArticleCategories(parent_id: number | null = null, refresh = false) {
        if(refresh) {
            this.offset = 0
            this.page = 1
        }

        this.categoryID = parent_id ?? 0

        let path = `/news_categories?limit=${this.limit}&page=${this.page}&with[]=image`

        if(parent_id) path += `&parent_id=${parent_id}`


        openApi('get', path)
          .then(result => {
              console.log(result.data)
              this.list = result.data
          })
          .catch(result => {
              console.log(result.response)
          })
    }

    getProducts() {

        let uri = `/products/list?limit=${this.limit}&page=${this.page}&filter[view]=1&with[]=image`
        if(this.filter_name) uri += `&filter[name]=${this.filter_name}`

        openApi('get', uri)
            .then((result) => {
                this.list = result.data.map((el: any) => ({...el, key: el.id}))
                this.total = result.total
            })
          .catch(err => console.log(err.response))
    }

    getContacts() {
        request('get', '/info/contacts', {}, true)
            .then(result => {
                console.log(result)
                this.list = result.data
                // this.total = result.data[1]
            })
    }

    getPoints() {
        openApi('get', `/places?limit=${this.limit}&page=${this.page}`)
            .then((result) => {
                this.list = result.data
                this.total = result.total
            })
    }

    getPointsGroups() {
        openApi('get', `/places/groups?limit=${this.limit}&page=${this.page}`)
            .then((result) => {
                this.list = result.data
                this.total = result.total
            })
    }

    getPromo() {
        request('get', `/product/promocode?limit=${this.limit}&offset=${this.offset}`)
            .then((response) => {
                this.total = response[1]
                this.list = response[0]
            })
            .catch((response) => {})
    }

    getFeedback() {
        openApi('get', `/feedback?limit=${this.limit}&page=${this.page}`)
            .then(result => {
                console.log(result)
                this.total = result.size
                this.page = result.page
                this.limit = result.limit
                this.list = result.data
            })
    }

    getForm() {
        let path = `/forms?limit=${this.limit}&page=${this.page}`

        openApi('get', path)
            .then(result => {
                console.log(result)
                this.total = result.size
                this.page = result.page
                this.limit = result.limit
                this.list = result.data
            })
    }

    getCategories(categoryID = 0, refresh = false) {
        console.log('getCategories')
        this.categoryID = categoryID
        if(refresh) {
            this.offset = 0
            this.page = 1
        }


        openApi('get', `/category/tree/${categoryID}`)
            .then(result => {
                console.log(result)
                this.list = result.data.map((el: any) => ({...el, key: el.id}))
                // this.total = result.total
            })
    }

    getStatuses() {
        request('get', '/shop/statuses/', {}, true).then((success) => {
            this.list = success.data[0]
            this.statuses = success.data[0]
        })
    }

    changePosition(product_id: number, action: string = 'up') {
        let array = this.select.value_array

        let this_elementIndex = array.findIndex((el: any) => el.id === product_id)

        if(this_elementIndex > -1) {

            array.sort((a, b) => {
                if (a.sorting !== b.sorting) {
                    return a.sorting - b.sorting; // по возрастанию
                }

                return a.id - b.id; // по возрастанию
            })

            let __array = array.map((el, index) => ({
                id: el.id,
                sorting: index
            }))

            let newIndex = __array.findIndex((el: any) => el.id === product_id)
            console.log(`index ${newIndex}`)

            if(action === 'up') {
                if(__array[newIndex-1]) {
                    this.swapSorting(__array, newIndex, newIndex-1)
                }
            } else {
                if(__array[newIndex+1]) {
                    this.swapSorting(__array, newIndex, newIndex+1)
                }
            }

            __array.sort((a, b) => {
                if (a.sorting !== b.sorting) {
                    return a.sorting - b.sorting; // по возрастанию
                }

                return a.id - b.id; // по возрастанию
            })
            this.select.value_array = __array

            let products: any = []

            __array.map((el: any) => {
                let index_product = __cpl.selectedConditionProducts.findIndex((e: any) => e.id === el.id)
                console.log(el.id)

                if(index_product > -1) {
                    products.push(__cpl.selectedConditionProducts[index_product])
                }
            })

            __cpl.selectedConditionProducts = products
        }
    }

    swapSorting(arr: any, index1: number, index2: number) {
        if (index1 < arr.length && index2 < arr.length) {
            const temp = arr[index1].sorting;
            arr[index1].sorting = arr[index2].sorting
            arr[index2].sorting = temp;
        }
    }

}

export default new __select()