import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../../helpers/request'
import { FormEvent } from 'react'
import i from '../../../../translations/i'
import { notification } from 'antd'

interface IntervalInterface {
    id: number
    type: string
    enabled: boolean
    express: boolean
    time_start: string | number | null
    time_end: string | number | null
    assembly_time: number
    minimum_order_value: number | null
    free_cart_value: number | null
    max_cart_value: number | null
    availability_in_days: number | null
    in_what_time: number | null
    delivery_price: number | null
}

class __delivery_intervals {

    modal = false
    modal_days = false

    interval: IntervalInterface = {
        id: 0,
        type: 'delivery',
        enabled: true,
        express: false,
        time_start: '',
        time_end: '',
        assembly_time: 0,
        minimum_order_value: 0,
        free_cart_value: 0,
        max_cart_value: null,
        availability_in_days: null,
        in_what_time: null,
        delivery_price: null,
    }

    massInterval: any = {
        type: 'delivery',
        enabled: true,
        express: false,
        time_start: '',
        time_end: '',
        assembly_time: 0,
        minimum_order_value: 0,
        free_cart_value: 0,
        max_cart_value: null,
        availability_in_days: null,
        in_what_time: null,
        delivery_price: null,
        minutes: 10
    }

    intervals: IntervalInterface[] = []

    days: any[] = []
    places: any[] = []
    zones: any[] = []

    not_working_days: any[] = []
    not_working_day: any = {
        date: null,
        day: null,
        month: null,
        year: null
    }

    modalMass = false

    constructor() {
        makeAutoObservable(this)
    }

    getTitleDay(day: number) {
        if(day === 1) return i.t('def:day.monday')
        else if(day === 2) return i.t('def:day.tuesday')
        else if(day === 3) return i.t('def:day.wednesday')
        else if(day === 4) return i.t('def:day.thursday')
        else if(day === 5) return i.t('def:day.friday')
        else if(day === 6) return i.t('def:day.saturday')
        else if(day === 7) return i.t('def:day.sunday')
    }

    save() {
        const data = new FormData()

        let types_array = ['delivery', 'pickup', 'express']

        this.days.map((element: any) => {

            if(element.delivery.length || element.pickup.length || element.express.length) {
                data.append(`days[${element.day}][day]`, element.day)

                types_array.map((type_element: string) => {
                    if(element[type_element] && element[type_element].length) {
                        element[type_element].map((in_element: any, index: number) => {
                            if(in_element.id && in_element.id  > 0) data.append(`days[${element.day}][${type_element}][${index}][id]`, in_element.id)
                            if(Number.isInteger(in_element.delivery_price) && in_element.delivery_price >= 0) {
                                console.log(in_element.delivery_price)
                                data.append(`days[${element.day}][${type_element}][${index}][delivery_price]`, in_element.delivery_price)
                            }
                            if(Array.isArray(in_element.intervals)) in_element.intervals.map((int: number) => data.append(`days[${element.day}][${type_element}][${index}][intervals][]`, `${int}`))
                            if(in_element.zones && in_element.zones.length) in_element.zones.map((zone_id: number) => data.append(`days[${element.day}][${type_element}][${index}][zones][]`, `${zone_id}`))
                            if(in_element.places && in_element.places.length) in_element.places.map((place_id: number) => data.append(`days[${element.day}][${type_element}][${index}][places][]`, `${place_id}`))
                        })
                    }
                })
            }
        })

        openApi('post', '/setting/delivery_intervals/settings', data)
            .then((result) => {
                console.log(result)

                this.getSettings()

                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit')
                })
            })
    }

    addIfElse(key: string, index: number) {
        this.days[index][key].push({
            day: index,
            delivery_price: null
        })
    }

    deleteCondition(type: string, index: number, _i: number) {
        if (window.confirm(i.t('def:confirms.delete'))) {
            const element = this.days[index][type][_i]

            console.log(element)

            if(element.id && element.id > 0) {
                openApi('delete', `/setting/delivery_intervals/settings/${element.id}`)
                    .then(result => {
                        this.getSettings()

                        notification.success({
                            message: i.t('def:success.complete'),
                            description: i.t('def:success.delete')
                        })
                    })
            }

            this.days[index][type].splice(i, 1)
        }

    }

    getListIntervals() {
        openApi('get', '/setting/delivery_intervals')
            .then(result => {
                console.log(result)
                result = result.data
                result.map((el: any) => {
                    el.time_start = this.convertFromSecond(el.time_start)
                    el.time_end = this.convertFromSecond(el.time_end)
                })
                this.intervals = result
            })
    }

    getSettings() {
        openApi('get', '/setting/delivery_intervals/settings')
            .then(result => {
                console.log(result)
                result = result.data
                this.days = result.days
                this.places = result.places
                this.zones = result.zones
            })
            .catch(error => console.log(error))
    }

    convertFromSecond(time: number) {
        let hour = Math.floor(time / 60 / 60)
        let minutesSeconds = time - hour * 60 * 60
        return hour + ':' + minutesSeconds / 60
    }

    getOneInterval(interval_id: number | string) {
        if(interval_id > 0)
            openApi('get', `/setting/delivery_intervals/${interval_id}`)
                .then(result => {
                    result = result.data
                    result.time_start = this.convertFromSecond(result.time_start)
                    result.time_end = this.convertFromSecond(result.time_end)
                    this.interval = result
                    console.log(result)
                })
    }

    closeModal() {
        this.clearInterval()
        this.modal = false
        this.modal_days = false
    }

    saveInterval(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('enabled', `${+ this.interval.enabled}`)
        data.append('express', `${+ this.interval.express}`)
        data.append('type', this.interval.type)

        if(typeof this.interval.time_start === 'string') {
            let time_start_string = this.interval.time_start.split(':')
            data.append('time_start', `${+time_start_string[0] * 60 * 60 + +time_start_string[1] * 60}`)
        }

        if(typeof this.interval.time_end === 'string') {
            let time_end_string = this.interval.time_end.split(':')
            data.append('time_end', `${+time_end_string[0] * 60 * 60 + +time_end_string[1] * 60}`)
        }



        data.append('assembly_time', `${this.interval.assembly_time}`)
        if(this.interval.minimum_order_value) data.append('minimum_order_value', `${this.interval.minimum_order_value}`)
        if(this.interval.free_cart_value) data.append('free_cart_value', `${this.interval.free_cart_value}`)
        if(this.interval.max_cart_value) data.append('max_cart_value', `${this.interval.max_cart_value}`)
        if(this.interval.availability_in_days) data.append('availability_in_days', `${this.interval.availability_in_days}`)
        if(this.interval.in_what_time) data.append('in_what_time', `${this.interval.in_what_time}`)
        if(this.interval.delivery_price) data.append('delivery_price', `${this.interval.delivery_price}`)

        openApi('post', `/setting/delivery_intervals/${this.interval.id}`, data)
            .then(result => {
                console.log(result)
                this.closeModal()
                this.getListIntervals()

                notification.success({
                    message: i.t('def:success.complete'),
                    description: i.t('def:success.edit')
                })
            })
    }

    clearInterval() {
        this.interval = {
            id: 0,
            type: 'delivery',
            enabled: true,
            express: false,
            time_start: '',
            time_end: '',
            assembly_time: 0,
            minimum_order_value: 0,
            free_cart_value: 0,
            max_cart_value: null,
            availability_in_days: null,
            in_what_time: null,
            delivery_price: null
        }
    }

    createInterval(id: any = 0) {
        this.clearInterval()

        this.getOneInterval(id)

        this.modal = true
    }

    deleteInterval(id: number) {
        if (window.confirm(i.t('def:confirms.delete'))) {
            openApi('delete', `/setting/delivery_intervals/${id}`)
                .then(result => {
                    this.getListIntervals()
                    this.getSettings()

                    notification.success({
                        message: i.t('def:success.complete'),
                        description: i.t('def:success.delete')
                    })
                })
        }
    }

    openDay() {
        this.modal_days = true
    }

    getNotWorkingDays() {
        openApi('get', `/setting/delivery_intervals/not_working_days`)
            .then(result => {
                console.log(result)
                this.not_working_days = result.data
            })
    }

    setWorkingDay(day: number | null, date: number | null, month: number | null, year: number | null, every: boolean) {
        const data = new FormData()
        if(day) data.append('day', `${day}`)
        if(date) data.append('date', `${date}`)
        if(month) data.append('month', `${month}`)
        if(year) data.append('year', `${year}`)
        data.append('every', `${+ every}`)

        openApi('post', `/setting/delivery_intervals/not_working_days`, data)
            .then(result => {
                console.log(result)
                this.getNotWorkingDays()
                this.closeModal()

                this.not_working_day = {
                    date: null,
                    day: null,
                    month: null,
                    year: null
                }
            })
    }

    deleteWorkingDay(id: number) {
        openApi('delete', `/setting/delivery_intervals/not_working_days/${id}`)
            .then(result => {
                console.log(result)
                this.getNotWorkingDays()
            })
    }

    clickMassCreate() {
        this.modalMass = true
    }

    saveMass(e: FormEvent) {
        e.preventDefault()

        const data = new FormData()
        data.append('enabled', `${+ this.massInterval.enabled}`)
        data.append('express', `${+ this.massInterval.express}`)
        data.append('type', this.massInterval.type)

        if(typeof this.massInterval.time_start === 'string') {
            let time_start_string = this.massInterval.time_start.split(':')
            data.append('time_start', `${+time_start_string[0] * 60 * 60 + +time_start_string[1] * 60}`)
        }

        if(typeof this.massInterval.time_end === 'string') {
            let time_end_string = this.massInterval.time_end.split(':')
            data.append('time_end', `${+time_end_string[0] * 60 * 60 + +time_end_string[1] * 60}`)
        }


        if(this.massInterval.minutes) data.append('minutes', `${this.massInterval.minutes}`)
        data.append('assembly_time', `${this.massInterval.assembly_time}`)
        if(this.massInterval.minimum_order_value) data.append('minimum_order_value', `${this.massInterval.minimum_order_value}`)
        if(this.massInterval.free_cart_value) data.append('free_cart_value', `${this.massInterval.free_cart_value}`)
        if(this.massInterval.max_cart_value) data.append('max_cart_value', `${this.massInterval.max_cart_value}`)
        if(this.massInterval.availability_in_days) data.append('availability_in_days', `${this.massInterval.availability_in_days}`)
        if(this.massInterval.in_what_time) data.append('in_what_time', `${this.massInterval.in_what_time}`)
        if(this.massInterval.delivery_price) data.append('delivery_price', `${this.massInterval.delivery_price}`)

        openApi('post', `/setting/delivery_intervals/mass`, data)
          .then(result => {
              console.log(result)
              this.closeMass()
              this.getListIntervals()

              notification.success({
                  message: i.t('def:success.complete'),
                  description: i.t('def:success.edit')
              })
          })
    }

    closeMass() {
        this.modalMass = false
        this.massInterval = {
            type: 'delivery',
            enabled: true,
            express: false,
            time_start: '',
            time_end: '',
            assembly_time: 0,
            minimum_order_value: 0,
            free_cart_value: 0,
            max_cart_value: null,
            availability_in_days: null,
            in_what_time: null,
            delivery_price: null,
            minutes: 10
        }
    }

}

export default new __delivery_intervals()