import { observer } from "mobx-react-lite";
import i from "../../../translations/i";
import { Button, Col, Input, Row, Space, Table, Tooltip } from "antd";
import controller from "./coupon.controller";
import { SettingOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import __users from "../../users/users.controller";

const UserButton = observer(({ users, user }: any) => {
  return <Button
    type={users.find((e: any) => e.user_id === user.id) ? "primary" : "dashed"}
    onClick={() => controller.selectUser(user)}
  >
    {users.find((e: any) => e.user_id === user.id) ? i.t('def:Unselect') : i.t('def:Select')}
  </Button>
})

export const CouponExUsers = observer(({ users }: any) => {

  useEffect(() => {
    __users.getList()
  }, [])

  const columnsUsers = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: i.t('users:name'),
      key: 'name',
      render: (record: any) => `${record.firstname ?? ''} ${record.lastname ?? ''} ${record.surname ?? ''}`
    },
    {
      title: i.t('users:email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: i.t('users:phone'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: i.t('points:actions'),
      key: 'action',
      render: (text: any, record: any) => (
        <>
          <Space>
            <UserButton
              users={users}
              user={record}
            />

            <Tooltip title={i.t('def:actions.edit')}>
              <Button
                type="dashed"
                icon={<SettingOutlined />}
                href={`/users/${record.id}`}
                target="_blank"
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ]

  return(
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <label>{i.t('users:name')}</label>
          <Input
            value={__users.list_filter.name ?? ''}
            onChange={(e: any) => __users.setFilter('name', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <label>{i.t('users:phone')}</label>
          <Input
            value={__users.list_filter.phone ?? ''}
            onChange={(e: any) => __users.setFilter('phone', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <label>{i.t('users:email')}</label>
          <Input
            value={__users.list_filter.email ?? ''}
            onChange={(e: any) => __users.setFilter('email', e.target.value)}
          />
        </Col>
      </Row>

      <br/>

      <Table
        columns={columnsUsers}
        dataSource={__users.users}
        rowKey="id"
        pagination={{
          total: __users.list.count,
          current: __users.list.offset === 0 ? 1 : Math.floor(__users.list.offset / __users.list.limit) + 1,
          pageSize: __users.list.limit,
          onChange: (page, pageSize) => {
            __users.list.offset = page > 1 ? (page * __users.list.limit) - __users.list.limit : 0
            console.log(__users.list.offset)
            __users.getList()
          },
          onShowSizeChange: (current, size) => {
            __users.list.limit = size
            __users.list.offset = 0
            __users.getList()
          }
        }}
      />
    </>
  )
})